<template>
  <garnish-ofthe-day-component/>
</template>

<script>
import GarnishOftheDayComponent from "@/components/erp/baseData/garnish/GarnishOftheDayComponent";

export default {
  name: "GarnishOftheDay",
  components: {GarnishOftheDayComponent}
}
</script>

<style scoped>

</style>
