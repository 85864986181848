<template>
  <v-container fluid>
    <v-dialog scrollable fullscreen v-model="dialog">
      <v-card>
        <v-card-title class="px-0 pt-0">
          <v-toolbar elevation="0" class="mx-0 border-bottom">
            <v-toolbar-title class="primary--text">{{ $t('erp.lang_garnishOfTheDay') }}
            </v-toolbar-title>
            <v-spacer/>
            <v-btn @click="close" min-width="40" max-width="40" min-height="40" max-height="40" depressed class=" rounded-lg
            " color="error">
              <v-icon>close</v-icon>
            </v-btn>
            <template v-slot:extension>
              <v-tabs v-model="tab" v-if="!loading">
                <v-tab v-for="group in groups" :key="group.uuid">
                  {{ group.name }}
                </v-tab>
              </v-tabs>
              <v-progress-linear v-else indeterminate/>

            </template>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="pa-0 px-1 py-1">
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="group in groups" :key="group.uuid">

              <v-row no-gutters>
                <v-col cols="12" sm="4" md="3" lg="2" class="pl-1 mt-1" v-for="extra in group.garnish" :key="extra.id">


                  <v-card
                      @click="selectGarnish(extra)"
                      outlined
                      class="pt-1 pl-1 extra-card text-black pr-1 ma-0 rounded-0 image_size" elevation="0"
                      :class="is_selected(extra)? 'success' : ''"
                      v-bind:style="{
                  /*'background-image': 'url(' + 'https://img.3pos.de/unsafe/300x150/billsys_' + api.auth.posID+ '/original/' + extra.productImage  + ')',*/
                }">
                    <v-row class="pa-0  text-center" no-gutters>


                      <v-col class="mx-auto pa-0" cols="2">
                        <!--                    <v-icon class="text-primary rounded" @click="removeExtra(extraTab, extra)">remove
                                            </v-icon>-->
                      </v-col>

                      <v-col class="pointer  mx-auto pt-1 pb-0" cols="12">
                        <v-avatar class="checked-garnish" v-if="is_selected(extra)" size="30" color="success">
                          <v-icon color="white">check</v-icon>
                        </v-avatar>
                      </v-col>
                      <v-col class="mx-auto pointer   pa-0" cols="2">
                        <!--                    <v-icon class="text-primary rounded">add</v-icon>-->
                      </v-col>

                      <v-col class="text-center pb-0 pt-1" cols="12">
                        <v-avatar @click="selectGarnish(extra)" color="#2699FB" size="60"
                                  style="border: 1px solid #2699FB">
                          <img
                              @click="selectGarnish(extra)"
                              v-if="!!extra.garnishImage"
                              :src="extra.garnishImage"/>
                          <h1 @click="selectGarnish(extra)" v-else class="text-white pt-2">{{
                              (extra.name && extra.name.substring(0, 1)) || 'u'
                            }}</h1>
                        </v-avatar>
                      </v-col>
                      <v-col class="pb-0 pointer pt-1 ma-0 font-weight-bolder text-white" cols="12"
                      >
                     <span class="font-size-md font-weight-light" style="color: #2699FB !important;">
                       {{ extra.name || $t('generic.lang_unknown') }}
                     </span>
                        <h6 class="font-weight-bolder mt-0 font-size-md text-light mx-auto"
                            style="color: #2699FB !important;"
                        >{{
                            extra.price | currency
                          }}</h6>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="border-top">
          <v-btn @click="save"
                 :disabled="(Array.isArray(this.selected) && this.selected.length === 0) ||(this.loading || this.save_loading)"
                 :loading="this.save_loading" block
                 class="mx-auto" x-large color="primary">
            {{ this.$t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";
import {mapState} from 'vuex';

export default {
  name: "GarnishOftheDayComponent",
  data: () => ({
    dialog: true,
    loading: false,
    tab: 0,
    groups: [],
    selected: [],
    save_loading: false
  }),
  mounted() {
    this.loadSelectedGarnish();
    this.loadGarnishGroups();
  },
  computed: {
    ...mapState([
      'extras',
    ]),
    is_selected() {
      return (garnish) => {
        //check if this garnish is already exists
        let index = this.selected.indexOf(garnish.id)
        return index > -1
      }
    },
  },
  methods: {
    close() {
      this.$router.push({name: 'erp.baseData.garnish'})
    },
    loadSelectedGarnish() {
      if (typeof this.extras === 'object' && Array.isArray(this.extras.extras)) {
        this.selected = [...this.extras.extras.filter(
            (ext) => ext.active === 1
        ).map(
            (ext) => ext.id
        )
        ]
      }
    },
    save() {
      this.save_loading = true;
      this.axios.post(ENDPOINTS.ERP.GARNISH.BULK_ACTIVATION, {
        ids: this.selected
      })
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              this.groups = res.data.groups;
              this.close();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(() => (this.save_loading = false))
    },
    selectGarnish(garnish) {
      //check if this garnish is already exists
      let index = this.selected.indexOf(garnish.id);
      if (index > -1) {
        this.selected.splice(index, 1);
        return
      }
      this.selected.push(garnish.id)
    },
    loadGarnishGroups() {

      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.GARNISHGROUP.GET_ALl_WITH_ITEMS)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              this.groups = res.data.groups;
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
<style scoped>
.checked-garnish {
  position: absolute;
  right: 0;
  top: 0;
}

.mobile_extras_name {
  font-size: 9px !important;
}

#hider {
  background-color: #F1F9FF;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
  display: none;
}

.pointer {
  cursor: pointer;
}

.extra-card *, .extra-card-mobile * {
  cursor: pointer !important;
  user-select: none !important;
}

.extra-card {
  user-select: none !important;
  background-color: #F1F9FF !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


</style>
